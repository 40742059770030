export const API_URL_LOCAL = "http://localhost:3001";
export const API_URL_DEV = "https://dev-api.spendflo.com";
export const API_URL_STAGING = "https://staging-api.spendflo.com";
export const API_URL_PROD = "https://api-v2.spendflo.com";

export const DASHBOARD_URL_DEV = "https://dev.spendflo.com";
export const DASHBOARD_URL_DEMO = "https://demo.spendflo.com";
export const DASHBOARD_URL_PROD = "https://app.spendflo.com";
export const SUM_DASHBOARD_URL = "https://dev-api.spendflo.com";

export const API_BEARER_TOKEN_DEV =
  "xcy8egc8ot7qgo8q2ybfo8yv2hp9u2hve9vd8puiwd";
export const API_BEARER_TOKEN_PROD =
  "bi8d2u8gh28gd827hiu2deindi2hd782giuy2h3ih23dh823";
export const API_BEARER_TOKEN_STAGING =
  "bug874gy8urbcuhbewlfcnb2opefh92hf807b4oi2ebi2c8";

const isProduction = Boolean(process.env.REACT_APP_ENV === "prod");
const isStaging = Boolean(process.env.REACT_APP_ENV === "staging");

export const getBaseUrl = () => {
  if (isProduction) {
    return DASHBOARD_URL_PROD;
  }
  if (isStaging) {
    return DASHBOARD_URL_DEMO;
  }
  return DASHBOARD_URL_DEV;
};

export const getApiURL = () => {
  if (isProduction) {
    return API_URL_PROD;
  }
  if (isStaging) {
    return API_URL_STAGING;
  }
  return API_URL_DEV;
};

export const getSumApiURL = () => {
  return SUM_DASHBOARD_URL;
};

export const getToken = () => {
  if (isProduction) {
    return API_BEARER_TOKEN_PROD;
  }
  if (isStaging) {
    return API_BEARER_TOKEN_STAGING;
  }
  return API_BEARER_TOKEN_DEV;
};

export const setExternalAuthToken = (token) => {
  localStorage.setItem("authToken", token);
};

export const getExternalAuthToken = () => {
  return localStorage.getItem("authToken");
};
