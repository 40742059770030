export const JUSTIFY_VARIANTS = {
  between: "justify-between",
  around: "justify-around",
  center: "justify-center",
  evenly: "justify-evenly",
  start: "justify-start",
  end: "justify-end",
};

export const DIRECTION_VARIANTS = {
  row: "flex",
  col: "flex flex-col",
  "row-reverse": "flex flex-row-reverse",
};

export const ALIGN_ITEMS_VARIANTS = {
  start: "items-start",
  end: "items-end",
  center: "items-center",
  baseline: "items-baseline",
  stretch: "items-stretch",
};

export const FLEX_WRAP_VARIANTS = {
  wrap: "flex-wrap",
  reverse: "flex-wrap-reverse",
  nowrap: "flex-nowrap",
};
