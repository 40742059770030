import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import axios from 'axios';

// all useQuery will go through this fetcher fn unless explicitly written
const defaultQueryFn = async ({ queryKey }) => {
  try {
    const { data } = await axios.get(`${queryKey[0]}`);
    return data;
  } catch {
    return { error: 'error' };
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
    },
  },
});

const ReactQuerySettings = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      {children}
    </QueryClientProvider>
  );
};

export default ReactQuerySettings;
