import Flex from "../flex/Flex";

const Navbar = () => {
  return (
    <Flex
      direction="row"
      alignItems="center"
      width="w-full"
      height="h-16"
      justify="between"
      position="fixed"
      padding="px-8"
      className="shadow-md bg-white z-50"
    >
      <a href="/">
        <img src="/logo.svg" alt="logo" className="w-28 h-10" />
      </a>
     
    </Flex>
  );
};

export default Navbar;

// <div className="flex items-center ">
// <svg
//   xmlns="http://www.w3.org/2000/svg"
//   className="h-6 w-6 mr-4 text-gray-500"
//   fill="none"
//   viewBox="0 0 24 24"
//   stroke="currentColor"
//   strokeWidth={2}
// >
//   <path
//     strokeLinecap="round"
//     strokeLinejoin="round"
//     d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
//   />
// </svg>
// <svg
//   xmlns="http://www.w3.org/2000/svg"
//   className="h-12 w-12 text-gray-400"
//   viewBox="0 0 20 20"
//   fill="currentColor"
// >
//   <path
//     fillRule="evenodd"
//     d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
//     clipRule="evenodd"
//   />
// </svg>
// </div>