import { lazy, Suspense, useEffect } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { setExternalAuthToken } from "../../constants/Api";

const UsageAnalytics = lazy(() => import("../../pages/myUsage/UsageAnalytics"));
const SoftwareDetails = lazy(() =>
  import("../../pages/myUsage/SoftwareDetails")
);

const AppRoutes = () => {
  const search = useLocation().search;
  const history = useHistory();

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const id = queryParams.get("id");
    if (id) {
      setExternalAuthToken(id);
      queryParams.delete("id");
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, []);

  return (
    <Suspense fallback={<div></div>}>
      <Switch>
        <Route path="/usage/software-details" component={SoftwareDetails} />
        <Route exact path="/" component={UsageAnalytics} />
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;
