import {
  Usage,
  Dashboard,
  BuySoftware,
  MySubscriptions,
  Settings,
} from "../icon/Icon";
import { getBaseUrl } from "../../constants/Api"

export const navbarItems = [
  {
    url: getBaseUrl(),
    icon: Dashboard,
    title: "Dashboard",
    show: true,
  },
  {
    url: getBaseUrl() + "/buy-software",
    icon: BuySoftware,
    title: "Buy Software",
    show: true,
  },
  {
    url: getBaseUrl() + "/my-subscriptions",
    icon: MySubscriptions,
    title: "My Subscriptions",
    show: true,
  },
  {
    url: getBaseUrl() + "/settings/approvers",
    icon: Settings,
    title: "Settings",
    show: true,
  },
  {
    url: "/",
    icon: Usage,
    title: "Usage Analytics",
    show: true,
  },
];
