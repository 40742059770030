import { useState } from "react";
import { navbarItems } from "./Items";
import styles from "./SidebarItems.module.css";

const SidebarItems = () => {
  const [currentTab, setCurrentTab] = useState("Usage Analytics");

  return (
    <div>
      {navbarItems.map((item, key) => (
        <a key={key} href={item.url}>
          <div>
            <button
              className={
                currentTab === item.title
                  ? styles["sidebar-nav-active"]
                  : styles["sidebar-nav"]
              }
              type="button"
              onClick={() => setCurrentTab(item.title)}
            >
              <item.icon item={item} currentTab={currentTab} />
              <p className={styles["sibedar-title"]}>{item.title}</p>
            </button>
          </div>
        </a>
      ))}
    </div>
  );
};

export default SidebarItems;
