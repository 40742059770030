import Flex from "./components/flex/Flex";
import Navbar from "./components/navbar/Navbar";
import AppRoutes from "./components/routes/AppRoutes";
import Sidebar from "./components/sidebar/Sidebar";
import styles from "./App.module.css";

function App() {

  return (
    <div className={styles["page-layout"]}>
      <Navbar />
      <Flex direction="row">
        <Flex direction="row" width="w-72">
          <Sidebar />
        </Flex>

        <div className={styles["page-layout-background"]}>
          <AppRoutes />
        </div>
      </Flex>
    </div>
  );
}

export default App;
