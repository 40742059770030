import React from "react";
import {
  JUSTIFY_VARIANTS,
  DIRECTION_VARIANTS,
  ALIGN_ITEMS_VARIANTS,
  FLEX_WRAP_VARIANTS,
} from './FlexVariants';

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

const Flex = ({
  direction,
  justify,
  alignItems,
  flexWrap,
  width,
  height,
  margin,
  padding,
  position,
  children,
  className,
}) => {
  const FLEX_DIRECTION = DIRECTION_VARIANTS[direction];
  const FLEX_JUSTIFY = JUSTIFY_VARIANTS[justify];
  const ALIGN_ITEMS = alignItems ? ALIGN_ITEMS_VARIANTS[alignItems] : null;
  const FLEX_WRAP = flexWrap ? FLEX_WRAP_VARIANTS[flexWrap] : null;

  const customClass = classNames(
    FLEX_DIRECTION,
    FLEX_JUSTIFY,
    ALIGN_ITEMS,
    FLEX_WRAP,
    width,
    height,
    margin,
    padding,
    position,
    className
  );

  return <div className={customClass}>{children}</div>;
};

export default Flex;
