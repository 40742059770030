import SidebarItems from "../sidebarItem/SidebarItems";
import styles from "./Sidebar.module.css";

const Sidebar = () => {
  return (
    <div className={styles["sidebar-layout"]}>
      <nav className="mt-8 px-4 w-60">
        <SidebarItems />
      </nav>
    </div>
  );
};

export default Sidebar;
